import { domReady, qs, qsa } from 'lib/dom.js'
import { on } from 'lib/events.js'

domReady(_ => {
	qsa('#flash .flash').forEach(flash => {
		on(qs('button', flash), 'click', _ => {
			flash.remove()

			if (qsa('#flash .flash').length === 0) {
				qs('body').classList.remove('flash')
			}
		}, { passive: true })
	})
})
